<template>
  <el-dialog class="change-dialog-box" title="请选择要分配的代表" :visible.sync="dialogFormVisible">
    <div class="title-search-box">
      <el-input placeholder="搜索代表姓名/手机号" v-model="searchVal" clearable @input="onSearchTree">
        <el-button @click="onSearchTree" slot="prefix" type="text" icon="el-icon-search" style="color: #A3A3A3;padding-left:3px;"></el-button>
      </el-input>
      <div v-if="!isSingle" style="text-align:right;color: #0f0f0f;">已选{{ filterSelectList.length }}人</div>
    </div>
    <div class="select-org-box">
      <div v-show="!searchVal" class="layout-box layout-left-box">
        <el-tree class="custom-tree-style" ref="ElTree" :data="initOrgList" node-key="orgId" :props="defaultProps" :default-expanded-keys="defaultOpen" :expand-on-click-node="false" @node-click="onNodeClick" @node-expand="onNodeExpand" @node-collapse="onNodeCollapse">
          <template slot-scope="{ data }">
            <div class="custom-tree-node" :class="{ 'check-node': clickNodeKey === data.orgId }">
              <div class="icon-label">
                <div class="custom-icon-position" style="padding-top: 4px;">
                  <img v-if="openNodeKeyArr.includes(data.orgId) && data.child && data.child.length" src="@/assets/nav/open.png" alt="" />
                  <img v-else-if="data.child && data.child.length" src="@/assets/nav/close.png" alt="" />
                  <img v-show="data.status !== 1" src="@/assets/nav/forbid.png" alt="" />
                </div>
                <div v-if="data.orgName.length <= 10" class="text-ellipsis">{{ data.orgName }}</div>
                <el-tooltip v-else effect="dark" :content="data.orgName" placement="bottom">
                  <div class="text-ellipsis">{{ data.orgName }}</div>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="layout-box ">
        <div class="layout-right-box">
          <div>
            <div class="represent-info-box check-all-box">
              <span>共{{ representItemList.length }}条搜索结果</span>
              <div v-if="!isSingle">
                <span style="padding-right: 5px;">全选</span>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="searchCheckAllChange"></el-checkbox>
              </div>
            </div>
            <div style="height:10px;width:100%;"></div>
            <template v-for="item of representItemList">
              <div class="represent-info-box" :key="item.id + item.orgId">
                <span class="is-pointor select-item" @click="searchCheckChange(!item.isSelect, item)">{{ item.orgName }} - {{ item.name }} - {{ item.mobile }}</span>
                <el-checkbox v-model="item.isSelect" :disabled="item.isTrainSelect" @change="searchCheckChange($event, item)"></el-checkbox>
              </div>
            </template>
          </div>

          <div v-show="!searchVal">
            <div class="represent-info-box check-all-box">
              <span>{{ clickNodeObj.orgName }}</span>
              <div v-if="!isSingle">
                <span style="padding-right: 5px;">全选</span>
                <el-checkbox :indeterminate="isIndeterminate" :value="checkAll" @change="handleCheckAllChange"></el-checkbox>
              </div>
            </div>
            <template v-for="item of pageTableList">
              <div class="represent-info-box" :key="item.key">
                <span class="is-pointor select-item" @click="handleCheckChange(!item.isSelect, item)">{{ item.orgName }} - {{ item.representName || item.name }} - {{ item.representMobile || item.mobile }}</span>
                <el-checkbox :value="item.isSelect" :disabled="item.isTrainSelect" @change="handleCheckChange($event, item)"></el-checkbox>
              </div>
            </template>
          </div>
          <div v-if="(searchVal && !representItemList.length) || (!tableData.length && !searchVal)" class="no-table-text">
            <i v-if="isLoading" class="el-icon-loading" style="font-size: 36px;"></i>
            <span v-else>暂无数据</span>
          </div>
        </div>
        <el-pagination v-show="!searchVal" class="pagination-box" @current-change="onCurrentChange" :current-page="pageNo" :page-size="pageSize" :total="total" :pager-count="5" hide-on-single-page background small layout="total, prev, pager, next"></el-pagination>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="onConfirmSelect">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SelectOrgRepresentModel',
  props: {
    isShowPop: {
      type: Boolean,
      default: false,
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      },
    },
    initSelectList: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: String,
      default: '',
    },
    sceneId: {
      type: String,
      default: '',
    },
    // 是否是单选
    isSingle: Boolean,
    projectTargetId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['orgList']),
    initKey() {
      return this.formObj.representId + this.formObj.orgId
    },
    initOrgList: {
      get() {
        return this.filterList
      },
      set(list) {
        this.filterList = list
      },
    },
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose', val)
      },
    },
    defaultOpen: {
      get() {
        if (this.clickNodeKey) {
          return
        }
        let obj = this.initOrgList[0] || {}
        let id = obj?.orgId
        if (id) {
          this.remove(id)
          this.openNodeKeyArr.push(id)
        }
        this.onNodeClick(obj)
        return [obj.orgId]
      },
    },
    tableData: {
      get() {
        return this.initTableData
      },
      set(list) {
        this.initTableData = this.onCheckFun(list)
      },
    },
    representItemList: {
      get() {
        return this.initRepresentList
      },
      set(list) {
        this.initRepresentList = this.onCheckFun(list)
      },
    },
    pageTableList: {
      get() {
        return this.tableData.slice((this.pageNo - 1) * this.pageSize, this.pageNo * this.pageSize)
      },
    },
    filterSelectList() {
      return this.hasSelectList.filter((v) => !v.isTrainSelect)
    },
  },
  watch: {
    orgList: {
      immediate: true,
      handler(newVal) {
        this.checkOrgId = ''
        this.filterList = []
        this.checkList(this.orgList)
      },
    },
  },
  data() {
    return {
      open: false,
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true,
      },
      searchVal: '',
      timer: null,
      isLoading: true,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      initTableData: [],
      orgItemList: [],
      initRepresentList: [],
      selectRepKey: '',
      selectRepObj: {},
      checkOrgId: '',
      filterList: [],
      clickNodeKey: '',
      clickNodeObj: {},
      openNodeKeyArr: [],

      isIndeterminate: false,
      checkAll: false,
      hasSelectList: [],
      isDisable: false,
    }
  },
  methods: {
    ...mapActions(['getOrgList', 'factoryAllRepresent', 'getOrgSearchList', 'doctorChangeRepresent', 'getTrainRepresentList']),
    // ---------------- 工具函数 ------------------------------
    indexOf(val) {
      for (let i = 0; i < this.openNodeKeyArr.length; i++) {
        if (this.openNodeKeyArr[i] === val) {
          return i
        }
      }
    },
    remove(val) {
      let index = this.indexOf(val)
      if (index > -1) {
        this.openNodeKeyArr.splice(index, 1)
      }
    },
    onCheckFun(list) {
      // 全选状态 处理
      let initTableData = []
      let hasSelect = false
      let hasNoSelect = false
      list?.map((item, ind) => {
        let obj = { ...item, key: item.representId + '-' + item.orgId }
        initTableData.push(obj)

        if (item.isSelect) {
          hasSelect = true
        } else {
          hasNoSelect = true
        }
        if (hasSelect && hasNoSelect) {
          this.checkAll = false
          this.isIndeterminate = true
        } else if (!hasSelect) {
          this.checkAll = false
          this.isIndeterminate = false
        } else if (hasSelect && !hasNoSelect) {
          this.checkAll = true
          this.isIndeterminate = false
        } else if (!(hasSelect && hasNoSelect)) {
          this.checkAll = false
          this.isIndeterminate = false
        }
      })
      if (!list.length) {
        this.checkAll = false
        this.isIndeterminate = false
      }
      return initTableData
    },
    onCheckAllSelect(list) {
      // 全选操作 处理
      let newSelctList = []
      for (let item of list) {
        let num = 0
        this.hasSelectList.map((it, ind) => {
          if (it.key === item.key) {
            if (!item.isSelect) {
              this.hasSelectList.splice(ind, 1)
            }
          } else {
            num += 1
          }
        })
        if (num === this.hasSelectList.length) {
          if (item.isSelect) {
            this.hasSelectList.push(item)
          }
        }
        if (this.isSingle) {
          this.hasSelectList = [item]
        }
      }
    },
    onChangeOrgSelect(initList) {
      // 组织请求代表列表 处理
      let list = []
      initList?.map((item) => {
        let num = 0
        for (let it of this.hasSelectList) {
          if (item.representId + '-' + item.orgId === it.key) {
            list.push({
              ...item,
              isSelect: it.isSelect,
            })
          } else {
            num += 1
          }
        }
        if (num === this.hasSelectList.length) {
          list.push({ ...item, isSelect: false })
        }
      })
      return list
    },
    // ----------------------- end --------------------------
    handleCheckAllChange(val) {
      // 组织代表全选
      let list = JSON.parse(JSON.stringify(this.tableData))
      list.map((item) => {
        item.isSelect = val
      })
      this.tableData = list
      this.onCheckAllSelect(list)
    },

    handleCheckChange(val, it) {
      // 组织代表单选
      let list = JSON.parse(JSON.stringify(this.tableData))
      list.map((item) => {
        if (this.isSingle) {
          item.isSelect = false
        }
        if (item.key === it.key) {
          item.isSelect = val
        }
      })
      this.tableData = list
      this.onCheckAllSelect([{ ...it, isSelect: val }])
    },
    searchCheckAllChange(val) {
      // 搜索代表全选
      let list = JSON.parse(JSON.stringify(this.representItemList))
      list.map((item) => {
        item.isSelect = val
        this.handleCheckChange(val, item)
      })
      this.representItemList = list
      this.onCheckAllSelect(list)
    },
    searchCheckChange(val, it) {
      // 搜索代表单选
      let list = JSON.parse(JSON.stringify(this.representItemList))
      list.map((item) => {
        if (this.isSingle) {
          item.isSelect = false
        }
        if (item.key === it.key) {
          item.isSelect = val
        }
      })
      this.representItemList = list
      this.onCheckAllSelect([{ ...it, isSelect: val }])
      this.handleCheckChange(val, it)
    },

    onNodeClick(data) {
      // 节点被点击
      if (this.isDisable) return
      this.clickNodeKey = data.orgId
      this.clickNodeObj = data
      if (this.clickNodeKey) {
        this.onGetStaffList()
      }
    },
    onNodeExpand(data, node, component) {
      // 节点展开
      // console.log(data,node,component)
      this.openNodeKeyArr.push(data.orgId)
    },
    onNodeCollapse(data, node, component) {
      // 节点收起
      // console.log(data,node,component)
      this.remove(data.orgId)
    },
    onGetStaffList() {
      this.isDisable = true
      this.pageNo = 1
      this.representItemList = []
      this.tableData = []
      this.isLoading = true
      let req = { orgId: this.clickNodeKey }
      let fun = this.factoryAllRepresent
      if(this.type === 'train') {
        req = {
          orgId: this.clickNodeKey,
          sceneId: this.sceneId,
        }
        fun = this.getTrainRepresentList
      } else if(this.type === 'produced-project') {
        req = {
          orgId: this.clickNodeKey,
          projectTargetId: this.projectTargetId
        }
        fun = async(params) => await this.$axios.get(this.$API.assignableRepresent, {params})
      }
      fun(req).then(
        (res) => {
          this.isDisable = false
          this.tableData = this.onChangeOrgSelect(res.data || [])
          this.total = this.tableData.length
          this.isLoading = false
        },
        (rea) => {
          this.isDisable = false
          this.isLoading = false
        }
      )
    },
    onCurrentChange(option) {
      this.pageNo = option
    },
    onSearchTree(val) {
      this.representItemList = []
      this.tableData = this.tableData
      if (!this.searchVal) return
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.orgItemList = []
        this.isLoading = true
        this.getOrgSearchList({ keyword: this.searchVal.trim() }).then(
          (res) => {
            this.isLoading = false
            this.orgItemList = res.data?.orgItemList || []
            let initRepresentList = []
            res.data?.representItemList?.map((item, ind) => {
              if (item.status === 1) {
                let obj = { ...item, representId: item.id, representMobile: item.mobile, representName: item.name, key: item.id + '-' + item.orgId }
                initRepresentList.push(obj)
              }
            })
            this.representItemList = this.onChangeOrgSelect(initRepresentList)
          },
          (rea) => {
            this.isLoading = false
            this.$message.error(rea.message)
          }
        )
      }, 500)
    },

    onConfirmSelect() {
      this.dialogFormVisible = false
      this.$emit('onConfirmSelect', this.filterSelectList)
    },

    checkList(list) {
      for (let item of list) {
        if (item.orgName.toLowerCase().includes(this.searchVal.trim().toLowerCase())) {
          this.filterList.push(item)
        } else {
          if (item.child && item.child.length) {
            this.checkList(item.child)
          }
        }
      }
    },
    onCheck(data) {
      if (this.checkOrgId === data.orgId) {
        this.checkOrgId = ''
        return
      }
      this.checkOrgId = data.orgId
    },
  },
  mounted() {
    this.hasSelectList = JSON.parse(JSON.stringify(this.initSelectList))
  },
}
</script>

<style lang="scss" scoped>
.change-dialog-box {
  ::v-deep .el-dialog {
    min-width: 600px !important;

    .el-dialog__header {
      background-color: #ffffff;
      text-align: left;
      border-bottom: 1px solid #e1e1e1;

      .el-dialog__title {
        color: #0f0f0f;
        font-size: 14px;
      }

      .el-dialog__close {
        color: #909399 !important;
      }
    }
  }

  ::v-deep .el-dialog .el-dialog__body {
    padding: 15px 20px;
  }
}

.dialog-footer {
  text-align: center;
}

.title-search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;

  & > span {
    flex-shrink: 0;
  }

  & > div {
    width: 200px;
    margin: 0 !important;
  }

  ::v-deep .el-input__inner {
    background-color: #fafafa;
    border-radius: 20px;
  }
}

.custom-tree-style {
  ::v-deep .el-tree-node__content {
    height: 36px;
    margin-left: -27px;
  }

  ::v-deep .el-tree-node__expand-icon {
    position: relative;
    left: 26px;
    z-index: 50;
    opacity: 0;
  }

  ::v-deep .is-current > .el-tree-node__content {
    background: #ffffff !important;
  }

  & > ::v-deep .el-tree-node {
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;
  }
}

.icon-label {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;

  img {
    margin-right: 8px;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  height: 28px;
}

.check-node {
  background: #f6f7fb !important;
  color: #3d61e3;
}

.select-org-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #eeeeee;
  height: 300px;
}

.layout-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layout-left-box {
  width: 50%;
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.layout-left-box::-webkit-scrollbar {
  width: 0 !important;
}

.layout-right-box {
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
  background: #f6f7fb;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.layout-right-box::-webkit-scrollbar {
  width: 0 !important;
}

.check-all-box {
  width: 100%;
  padding: 5px 0px 5px 3px;
  background: #f6f7fb;
  position: absolute;
  top: 0;
  z-index: 10000;
  border-bottom: 1px solid #eeeeee;
}

.represent-info-box {
  padding: 10px;
  color: #0f0f0f;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-table-text {
  text-align: center;
  line-height: 60px;
  margin-top: 30px;
}

.pagination-box {
  width: 100%;
  padding: 5px 0px 5px 3px;
  background: #eeeeee;
  position: absolute;
  bottom: 0;
  z-index: 10000;
}

.search-tree-rest {
  font-size: 14px;
}

.rest-num {
  padding: 0 10px 10px;
  color: #787878;
}

.represent-item-box {
  padding: 0 10px;
  color: #0f0f0f;
  height: 36px;
  line-height: 36px;
}

.select-item {
  width: 100%;
}
</style>
