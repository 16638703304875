<!--
 * @Author: your name
 * @Date: 2021-08-03 11:05:10
 * @LastEditTime: 2021-08-21 17:26:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/child/SenceAllotItem.vue
-->
<template>
  <div class="allot-card-wrapper" :class="{'is-open':isOpen}">
    <el-row
      class="allot-card-header"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <div class="font-s18 font-weight color-main">{{ title }}</div>
      <div v-if="sceneType !== 7">覆盖医生：{{ currentItemData.doctorCount }}人</div>
      <el-row type="flex" align="middle">
        <el-input
          v-if="sceneType !== 7"
          v-model="currentInvite"
          placeholder="批量设置任务数"
          :min="0"
          type="number"
          @change="onChangeInvite"
        />
        <i class="el-icon-arrow-down label-wrapper" :class="{'is-rotate': isOpen}" @click="onChangeOpen" />
        <i class="el-icon-delete label-wrapper" @click="onDelCard" />
      </el-row>
    </el-row>
    <div class="allot-card-body">
      <el-table
        :data="representList"
        row-key="representId"
        style="width: 100%;"
        :max-height="scrollHeight"
      >
        <el-table-column
          prop="name"
          label="姓名"
        />
        <el-table-column
          prop="orgName"
          label="所在组织"
          align="center"
        />
        <el-table-column
          v-if="sceneType !== 7"
          prop="doctorCount"
          label="绑定医生数"
          align="center"
        />
        <el-table-column
          v-if="sceneType !== 7"
          label="分配任务数"
          align="center"
        >
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.inviteCount" size="small" :min="0" />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="text-primary-link" @click="onDelRep(scope.row.representId,scope.row.orgId)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SceneAllotForRepItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    codeMoney: {
      type: [Number, String],
      default: 10
    },
    representList: {
      type: Array,
      default() {
        return []
      }
    },
    sceneType: { // 兼容培训
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isOpen: true,
      currentInvite: ''
    }
  },
  computed: {
    scrollHeight() {
      return this.isOpen ? 400 : 0
    },
    currentItemData() {
      let doctorCount = 0
      let cost = 0
      this.representList.map(v => {
        doctorCount += v.doctorCount
        cost += (v.inviteCount || 0) * this.codeMoney
      })
      return {
        doctorCount: doctorCount,
        representCount: this.representList.length,
        totalCost: cost
      }
    }
  },
  methods: {
    onChangeOpen() {
      this.isOpen = !this.isOpen
    },
    onDelCard() {
      this.$emit('onDelCard')
    },
    onDelRep(currentId, currentOrgId) {
      console.log(currentId, currentOrgId)
      this.$emit('onDelRep', currentId, currentOrgId)
    },
    onChangeInvite(value) {
      this.$emit('onChangeInvite', value)
    }
  }
}
</script>
<style lang="scss" scoped>
  .allot-card-wrapper {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ebeef5;
    margin-bottom: 20px;
    .allot-card-header {
      background: #eee;
      padding: 10px;
      padding-right: 28px;
      font-size: 14px;
    }
    .label-wrapper {
      font-size: 24px;
      font-weight: bold;
      margin-left: 25px;
      cursor: pointer;
      transition: ease 250ms;
      transform: rotate(0deg);
      &.is-rotate {
        transform: rotate(180deg);
      }
    }
  }
</style>
