<template>
  <div style="height: 100%; width: 100%;">
    <div ref="categoryChart" style="height: 100%; width: 100%;" />
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'EchartsFunnel',
  props: {
    option: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.find()
  },
  created() {},
  methods: {
    find() {
      this.chart = echarts.init(this.$refs.categoryChart)
      this.chart.setOption(this.option)
    }
  }
}
</script>
