<template>
  <el-dialog
    title="确认分配学术任务"
    width="40%"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="lay-box">
      <div>任务名称：<span class="font-weight">{{ sceneData.title }}</span></div>
      <div v-if="sceneType !== 7">
        <span>学术任务：</span>
        <span class="pdg-r30">代表执行时间：<span class="font-weight">{{ sceneData.representValidDay }}天 </span></span>
        <span> 医生学习时间：<span class="font-weight">{{ sceneData.doctorStudyDate }}天</span></span>
      </div>
      <div v-if="sceneType === 7">任务对象：<span class="font-weight">{{ allotList.length }}名代表，{{ sceneData.money || 0 }}积分/人</span></div>
      <div v-else>任务对象：<span class="font-weight">{{ allotList.length }}名{{ allotTypeData[allotType] }}，{{ sceneData.totalInviteCount }}个任务</span></div>
    </div>

    <div class="wrapper-box">
      <el-table
        v-if="allotType === 'represent'"
        :data="allotList"
        width="100%"
        stripe
      >
        <el-table-column label="员工姓名" prop="name" />
        <el-table-column label="所在组织" prop="orgName" align="center" />
        <el-table-column label="任务数量" prop="inviteCount" align="center" />
        <el-table-column v-if="sceneType === 7" label="总积分" align="center">{{ sceneData.money || 0 }}</el-table-column>
      </el-table>
      <el-table
        v-else-if="allotType === 'department'"
        :data="allotList"
        width="100%"
        stripe
      >
        <el-table-column label="组织名称" prop="factoryName" />
        <el-table-column label="组织人数" prop="representCount" align="center" />
        <el-table-column label="任务数量" prop="inviteCount" align="center" />
        <el-table-column v-if="sceneType === 7" label="总积分" align="center">{{ sceneData.money || 0 }}</el-table-column>
      </el-table>
      <el-table
        v-else
        :data="allotList"
        width="100%"
        stripe
      >
        <el-table-column label="医生姓名" prop="doctorName" />
        <el-table-column label="医生编码" prop="doctorCode" align="center" />
        <el-table-column label="医生职称" prop="title" align="center" />
        <el-table-column label="所在医院" prop="hospital" align="center" />
      </el-table>
    </div>
    <div v-if="sceneType === 7">
      <div class="fix-wrapper col7784a1">账户可支配余额：{{ sceneData.userLess }}</div>
      <div class="fix-wrapper">
        <span>本次任务消耗：</span>
        <span class="font-s18 font-weight">{{ sceneData.totalCost }}</span>
      </div>
    </div>
    <template slot="footer">
      <el-row type="flex" justify="center">
        <el-button
          type="primary"
          plain
          @click="onClose"
        >
          返回修改
        </el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="onSubmit"
        >
          确认分配
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'SceneAllotModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    allotType: {
      type: String,
      default: 'represent'
    },
    sceneType: {
      type: Number,
      default: 0
    },
    sceneData: {
      type: Object,
      default() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    allotList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      allotTypeData: {
        represent: '代表',
        doctor: '医生',
        department: '组织'
      }
    }
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },
    onSubmit() {
      this.$emit('onSubmit')
    }
  }
}
</script>
<style lang="scss" scoped>
.lay-box {
  padding-bottom: 15px;
  font-size: 12px;
}
.wrapper-box {
  font-size: 14px;
  height: 300px;
  overflow-y: auto;
  text-align: center;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
}
.fix-wrapper {
  font-size: 14px;
  text-align: right;
}
</style>
